import { maxLength, required } from 'vuelidate/lib/validators';

import {
  chass,
  count
} from './../../../../shared/components/wo/models/wo-validation';

export class Damage {
  division: string = '';
  yard: string = '';
  status: string = '';
  location: string = '';

  truck: string = '';
  plate: string = '';
  driverId: string = '';
  odometer: string = '';

  date: string = '';

  truckLights: boolean = false;
  truckHeadStop: boolean = false;
  truckTailDash: boolean = false;
  truckTurnIndicators: boolean = false;
  truckMirrors: boolean = false;
  truckWindows: boolean = false;
  truckWindshield: boolean = false;
  truckWipers: boolean = false;
  truckFuelTank: boolean = false;
  truckRearEnd: boolean = false;
  truckReflectors: boolean = false;
  truckMufflers: boolean = false;
  truckBodyCondition: string = '';
  truckSafetyEq: boolean = false;
  truckFireExt: boolean = false;
  truckSpareTire: boolean = false;
  truckDefroster: boolean = false;
  truckAirLines: boolean = false;
  truckAC: boolean = false;
  truckHorn: boolean = false;
  truckFifthWheel: boolean = false;
  truckReleaseLever: boolean = false;
  truckHubcap: boolean = false;
  truckELD: boolean = false;
  truckDashcam: boolean = false;
  truckAirCompressor: boolean = false;
  truckBattery: boolean = false;
  truckBrakes: boolean = false;
  truckClutch: boolean = false;
  truckDriveshaft: boolean = false;
  truckEngine: boolean = false;
  truckFluidLevel: boolean = false;
  truckFuelFilter: boolean = false;
  truckOilPressure: boolean = false;
  truckRadiator: boolean = false;
  truckSprings: boolean = false;
  truckTransmission: boolean = false;

  truckRegistration: boolean = false;
  truckIFTA: boolean = false;
  truckCOI: boolean = false;

  truckOther: string = '';

  streetTiresLeft: boolean = false;
  streetTiresRight: boolean = false;

  driveTiresLFO: boolean = false;
  driveTiresLFI: boolean = false;
  driveTiresRFI: boolean = false;
  driveTiresRFO: boolean = false;
  driveTiresLRO: boolean = false;
  driveTiresLRI: boolean = false;
  driveTiresRRI: boolean = false;
  driveTiresRRO: boolean = false;

  chassisReg: boolean = false;
  chassisBIT: string = null; // date
  chassisAnnual: string = null; // date
  chassisLights: boolean = false;
  chassisReflector: boolean = false;
  chassisMudflap: boolean = false;
  chassisLandingGear: boolean = false;
  chassisDoor: boolean = false;
  chassisSide: boolean = false;
  chassisRoof: boolean = false;
  chassisKingpin: boolean = false;
  chassisBrakes: boolean = false;
  chassisCoupling: boolean = false;
  chassisSuspension: boolean = false;
  chassisHubcap: boolean = false;
  chassisOther: string = '';

  chassisTireLFOOriginal: boolean = false;
  chassisTireLFODamaged: boolean = false;
  chassisTireLFIOriginal: boolean = false;
  chassisTireLFIDamaged: boolean = false;
  chassisTireRFIOriginal: boolean = false;
  chassisTireRFIDamaged: boolean = false;
  chassisTireRFOOriginal: boolean = false;
  chassisTireRFODamaged: boolean = false;

  chassisTireLROOriginal: boolean = false;
  chassisTireLRODamaged: boolean = false;
  chassisTireLRIOriginal: boolean = false;
  chassisTireLRIDamaged: boolean = false;
  chassisTireRRIOriginal: boolean = false;
  chassisTireRRIDamaged: boolean = false;
  chassisTireRROOriginal: boolean = false;
  chassisTireRRODamaged: boolean = false;

  containerNumber: string = '';
  truckDamage: boolean = false;
  truckTire: boolean = false;
  chassisNumber: string = '';
  chassisDamage: boolean = false;
  chassisTire: boolean = false;
  originalTire: string = '';

  files = [];
}

export const damageReportValidation = {
  truck: { required, maxLength: maxLength(6) },
  plate: { required },
  driverId: { required },
  odometer: { required },
  division: { required },
  location: { required },
  date: { required },
  containerNumber: { count },
  chassisNumber: { chass }
};
