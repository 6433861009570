
































import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { DamageReportHeaders } from './models/DamageReportHeaders';

import DamageReportFilters from '@/pages/YMS/DamageReport/DamageReportFilters.vue';
import DamageReportAction from '@/pages/YMS/DamageReport/DamageReportAction.vue';

import { YmsModule } from '@/store/index';
import damageReportEventBus from './models/eventBus';
import { YmsType } from '@/pages/YMS/models/yms.model';
import { ToastHelper } from '@/utils/toast.util';

@Component({
  components: { TmsTable, DamageReportFilters, DamageReportAction }
})
export default class DamageReport extends Vue {
  columns: TableHeaders[] = DamageReportHeaders;
  damageReportId: any = null;

  YmsModule = YmsModule;

  get damageList() {
    return this.YmsModule.ymsData;
  }

  get isLastPage(): boolean {
    return !this.YmsModule.meta.lastKey;
  }

  constructor() {
    super();
  }

  openModal(rowData) {
    YmsModule.setYmsType(YmsType.DAMAGE);
    this.damageReportId = rowData.data.id;
    this.$bvModal.show('damage-report-modal');
  }

  search() {
    YmsModule.search({
      type: YmsType.DAMAGE
    });
  }
}
