




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { FilterInput } from '@/shared/services/filter/filter.service';
import { CompanyService } from '@/shared/services/mater-data/company.service';
import { convertDateFieldToAPIFormatWithoutTime } from '@/utils/date.util';

@Component({
  components: { Fragment }
})
export default class SelectionFilter extends Vue {
  @Prop() filter: FilterInput<string>;
  @Prop() selectionTypesList: [{ value: string; text: string }];
  @Prop() autocompleteFieldsList: string[];

  search: string = null;
  searchTypeahed: any = null;
  selectedType = null;
  isAutocompleteField = false;
  companyService = CompanyService;
  convertDateFieldToAPIFormatWithoutTime = convertDateFieldToAPIFormatWithoutTime;

  constructor() {
    super();
  }

  created() {
    this.selectedType = this.selectionTypesList[0].value;
  }

  fieldChange() {
    this.filter.field = this.selectedType;
    this.isAutocompleteField = [...this.autocompleteFieldsList].includes(
      this.selectedType
    );

    this.search = null;
    this.filter.search = null;
  }

  selectTypeadhead(val) {
    this.filter.search = val?.id;
  }
}
