import {
  CellContentType,
  TableHeaders
} from '@/shared/components/table/models';

export const DamageReportHeaders: TableHeaders[] = [
  {
    key: 'status',
    label: `Status`,
    sortable: true
  },
  {
    key: 'division',
    label: `DIV`,
    sortable: true
  },
  {
    key: 'yard',
    label: `YARD`,
    sortable: true
  },
  {
    key: 'location',
    label: `LOCA`,
    sortable: true
  },
  {
    key: 'createdBy',
    label: `Created By`,
    sortable: true
  },
  {
    key: 'createdDate',
    label: `DATE`,
    sortable: true,
    disabled: true,
    content: {
      type: CellContentType.Date
    }
  },
  {
    key: 'time',
    label: `TIME`,
    sortable: true,
  },
  {
    key: 'driverId',
    label: `Driver`,
    sortable: true
  },
  {
    key: 'truck',
    label: `Truck`,
    sortable: true
  },
  {
    key: 'plate',
    label: `Plate`,
    sortable: true
  },
  {
    key: 'body',
    label: `BODY`,
    sortable: true
  },
  {
    key: 'truckTire',
    label: `TIRE`,
    sortable: true
  },
  {
    key: 'containerNumber',
    label: `CONT #`,
    sortable: true
  },
  {
    key: 'chassisNumber',
    label: `Chassis #`,
    sortable: true
  },
  {
    key: 'chassisDamage',
    label: `DMG`,
    sortable: true
  },
  {
    key: 'chassisTire',
    label: `Tire`,
    sortable: true
  },
  {
    key: 'or',
    label: `O/R`,
    sortable: true
  }
];
