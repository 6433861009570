


















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { FilterInput } from '@/shared/services/filter/filter.service';

@Component({
  components: {
    Fragment
  }
})
export default class YMSTypeFilter extends Vue {
  @Prop() filter: FilterInput<string>;

  options = [
    { value: null, text: 'All' },
    { value: 'damaged', text: 'Damaged' },
    { value: 'repaired', text: 'Repaired' }
  ];

  constructor() {
    super();
  }
}
